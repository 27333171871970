/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable spaced-comment */
import {
  ModalContent,
  Card,
  Common,
  Icon,
  DeliveryStatusLabel,
} from "@/components";
import { manifest, manifestLine, route } from "../models";
import { useCallback, useEffect, useState } from "react";
import { useMoveRoute, useSwapRoute } from "../services/deliveryManifest";
import { SnackbarUtils } from "@/utils";
import { useAppDispatch } from "@/sharedStore/hooks";
import { RouteCombobox } from "./RouteCombobox";
import { useGetRouteList } from "../providers/deliveryManifest/hooks";
import {
  CanModifyRouteByStatus,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import clsx from "clsx";

interface Props {
  open: boolean;
  onClose: () => void;
  currentData: manifest;
  manifests: manifest[];
  onSubmit: (position: number) => void;
  onCompleted: () => void;
  date: Date;
}

export const ModalManifestMove = ({
  open,
  onSubmit,
  onClose,
  currentData,
  manifests,
  onCompleted,
  date,
}: Props) => {
  const [step, setStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(date);

  const dataRoute = useGetRouteList();
  const [routes, setRoutes] = useState<any>([]);
  const [routeSelected, setRouteSelected] = useState<route>(currentData.route);

  const moveRoute = useMoveRoute();
  const [manifestLines, setManifestLines] = useState<manifestLine[]>([]);
  const [selectedManifestLines, setSelectedManifestLines] = useState<
    manifestLine[]
  >([]);
  const { isLoading } = moveRoute;
  const dispatch = useAppDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleClose = () => {
    onClose();
    onSubmit(1);
  };
  const handleCompleted = () => {
    onCompleted();
  };

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);
  useEffect(() => {
    setRoutes(dataRoute);
  }, [dataRoute]);
  useEffect(() => {
    setRouteSelected(currentData.route);
  }, [currentData]);
  useEffect(() => {
    setManifestLines(currentData.manifestLines);
    setSelectedManifestLines([]);
    setStep(0);
    setSearchKeyword("");
  }, [currentData]);

  const handleChange = (route: route) => {
    setRouteSelected(route);
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };
  const handleNext = () => {
    setStep(step + 1);
  };
  const handleSave = () => {
    const docIdList = selectedManifestLines.map((item) => item.id);
    moveRoute.mutate(
      {
        manifestId: currentData.id,
        destRouteId: routeSelected.id,
        docIdList,
      },
      {
        onSuccess(data) {
          SnackbarUtils.success(
            `${docIdList.length} item${
              docIdList.length > 1 ? "s" : ""
            } of route <b>${
              currentData.route.name
            }</b> has been moved to route <b>${
              routeSelected.name
            }</b> successfully.`
          );

          handleClose();
          handleCompleted();
        },
      }
    );
  };

  const handleSelectManifestLine = (manifestLine: manifestLine) => {
    setSelectedManifestLines(
      selectedManifestLines.includes(manifestLine)
        ? selectedManifestLines.filter((item) => item.id !== manifestLine.id)
        : selectedManifestLines.concat(manifestLine)
    );
  };

  const handleChangeDate = useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  const RouteItem = ({
    isCurrent,
    isActive,
    data,
    disable,
    onClick,
  }: {
    isCurrent?: boolean;
    isActive?: boolean;
    disable?: boolean;
    data: route;
    onClick: () => void;
  }) => {
    const isNoAction = isCurrent || disable || isActive;
    return (
      <div
        className={clsx(
          "text-sm flex items-center mb-0.5 p-3",
          !isNoAction && "hover:bg-neutral-10 cursor-pointer",
          isCurrent && "bg-neutral-20",
          isActive && "bg-neutral-10",
          disable && "bg-neutral-5"
        )}
        onClick={() => (isNoAction ? {} : onClick())}
      >
        <div>
          <Common.DotColor style={{ backgroundColor: data.colour }} />
        </div>
        <span className="font-semibold mx-1.5 line-clamp-1">{data.name}</span>
        {data.status !== DeliveryStatusValue.PENDING && (
          <DeliveryStatusLabel status={data.status} className="mr-2" />
        )}
        {isCurrent && (
          <span className="text-neutral-50 whitespace-nowrap mr-2">
            ( Current )
          </span>
        )}
        {isActive && (
          <div className="ml-auto">
            <Icon.Checked size="14" className="fill-blue" />
          </div>
        )}
      </div>
    );
  };

  return (
    <ModalContent
      open={open}
      onClose={handleClose}
      panelClassWidth="max-w-[30rem] w-full"
    >
      {routes && selectedManifestLines && (
        <Card title={"Move route"}>
          <div className="p-4">
            {step == 1 && (
              <>
                <div>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="max-w-[9rem]">
                        <Common.DatePicker
                          selected={selectedDate}
                          onChange={handleChangeDate}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <Common.Input
                          iconRight={Icon.Search}
                          iconType="stroke"
                          placeholder="Search route name"
                          value={searchKeyword}
                          onChange={(e) => setSearchKeyword(e.target.value)}
                          onClear={() => setSearchKeyword("")}
                        />
                      </td>
                    </tr>
                  </table>
                </div>

                <div className="pt-2">
                  <div className="h-25 overflow-y-auto mt-3">
                    {manifests && manifests.length ? (
                      <>
                        {routes.length ? (
                          routes.map((route: route) => {
                            const disabled = !CanModifyRouteByStatus.includes(
                              route.status
                            );
                            return (
                              <RouteItem
                                key={route.id}
                                data={route}
                                onClick={() => handleChange(route)}
                                isCurrent={currentData.id === route.id}
                                isActive={routeSelected?.id === route.id}
                                disable={disabled}
                              />
                            );
                          })
                        ) : (
                          <Common.NoData title={"No Route founded"} />
                        )}
                      </>
                    ) : (
                      <Common.NoData title={"No Route data!"} />
                    )}
                  </div>
                </div>
              </>
            )}
            {step == 0 && (
              <>
                <div className="font-bold mb-4">Select document:</div>
                <div className="min-h-[5rem]">
                  {manifestLines &&
                    manifestLines.map((item: manifestLine, index: number) => (
                      <div key={item.id} className="px-1 py-1">
                        <Common.Checkbox
                          id={item.id}
                          checked={selectedManifestLines.includes(item)}
                          onChange={() => handleSelectManifestLine(item)}
                          ipSize="md"
                          label={
                            "#" +
                            item.document.saleDocumentNumber +
                            (item.document.customerTradingName
                              ? ` - ${item.document.customerTradingName}`
                              : "")
                          }
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className="shadow-overline px-4 py-3 flex space-x-2 justify-end">
            <Common.Button
              disabled={isLoading}
              onClick={handleClose}
              color="transparent"
            >
              Cancel
            </Common.Button>
            {step == 0 && (
              <Common.Button
                disabled={isLoading || selectedManifestLines.length == 0}
                isLoading={isLoading}
                onClick={() => handleNext()}
              >
                Next
              </Common.Button>
            )}
            {step == 1 && (
              <Common.Button
                disabled={isLoading}
                isLoading={isLoading}
                color="transparent"
                onClick={() => handlePrevious()}
              >
                Previous
              </Common.Button>
            )}
            {step == 1 && (
              <Common.Button
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => handleSave()}
              >
                Save
              </Common.Button>
            )}
          </div>
        </Card>
      )}
    </ModalContent>
  );
};
