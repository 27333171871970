import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash";
import {
  ModalContent,
  Card,
  Common,
  Icon,
  CustomScrollbar,
} from "@/components";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import {
  routificSettingsLoadedSelector,
  routificSettingsLoadingSelector,
  routificSettingsSelector,
} from "../providers/deliveryManifest/selector";
import { routificSetting } from "../models";
import { updateRoutificDefaultSettings } from "../providers/deliveryManifest/sliceServices";
import { updateRoutificSettings } from "../providers/deliveryManifest/slice";
import { SnackbarUtils } from "@/utils";

const traffic = [
  {
    id: 0,
    text: "Faster",
    value: "faster",
  },
  {
    id: 1,
    text: "Fast",
    value: "fast",
  },
  {
    id: 2,
    text: "Normal",
    value: "normal",
  },
  {
    id: 3,
    text: "Slow",
    value: "slow",
  },
  {
    id: 4,
    text: "Very slow",
    value: "very slow",
  },
];

const visitBalance = [
  {
    id: 0,
    text: "Most optimised",
    value: 0,
  },
  {
    id: 2,
    text: "Most balanced",
    value: 100,
  },
];

const capacityTypes = [
  {
    id: 0,
    text: "Not Applicable",
    value: 0,
  },
  {
    id: 1,
    text: "Total Order Amount",
    value: 1,
  },
  // {
  //   id: 2,
  //   text: "Weight and Volume",
  //   value: 2,
  // },
];

const validationSchema = yup.object().shape({
  maxVisitLateness: yup
    .number()
    .min(0, "Max visit lateness must be greater than or equal to 0.")
    .required("Please enter Max visit lateness number.")
    .typeError("Max visit lateness is a number!"),
  maxVehicleOvertime: yup
    .number()
    .min(0, "Max vehicle overtime must be greater than or equal to 0.")
    .required("Please enter Max vehicle overtime number.")
    .typeError("Max vehicle overtime is a number!"),
  squashDurations: yup
    .number()
    .min(0, "Squash durations must be greater than or equal to 0.")
    .required("Please enter Squash durations number.")
    .typeError("Squash durations is a number!"),
});

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ModalOptimizeConfigration = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const routificSettings = useAppSelector(
    routificSettingsSelector
  ) as routificSetting;
  const routificSettingsLoading = useAppSelector(
    routificSettingsLoadingSelector
  );
  const routificSettingsLoaded = useAppSelector(routificSettingsLoadedSelector);
  const [isApplyAsDefaultSettings, setApplyAsDefaultSettings] =
    useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      shortestDistance: routificSettings.shortestDistance,
      visitBalanceCoefficient: routificSettings.visitBalanceCoefficient || 0,
      traffic: routificSettings.traffic || "fast",
      maxVisitLateness: routificSettings.maxVisitLateness || 0,
      maxVehicleOvertime: routificSettings.maxVehicleOvertime || 0,
      squashDurations: routificSettings.squashDurations || 0,
      avoidTolls: routificSettings.avoidTolls,
      capacityType: routificSettings.capacityType,
    },
    validationSchema,
    onSubmit: (values: routificSetting) => {
      if (isApplyAsDefaultSettings) {
        dispatch(updateRoutificDefaultSettings(values)).then(() => {
          SnackbarUtils.success("Apply as default settings successfully.");
          formik.resetForm({ values });
          onClose();
          setApplyAsDefaultSettings(false);
        });
      } else {
        dispatch(updateRoutificSettings(values));
        formik.resetForm({ values });
        onClose();
        setApplyAsDefaultSettings(false);
      }
    },
  });

  useEffect(() => {
    if (open) {
      !isEmpty(routificSettings) &&
        formik.setValues({
          shortestDistance: routificSettings.shortestDistance,
          visitBalanceCoefficient:
            routificSettings.visitBalanceCoefficient || 0,
          traffic: routificSettings.traffic || "fast",
          maxVisitLateness: routificSettings.maxVisitLateness,
          maxVehicleOvertime: routificSettings.maxVehicleOvertime || 0,
          squashDurations: routificSettings.squashDurations,
          avoidTolls: routificSettings.avoidTolls,
          capacityType: routificSettings.capacityType,
        });
      setTimeout(() => {
        ReactTooltip.rebuild();
      }, 100);
    } else {
      ReactTooltip.hide();
    }
  }, [open, routificSettings]);

  return (
    <ModalContent open={open} onClose={onClose}>
      {routificSettingsLoading && !routificSettingsLoaded && (
        <div className="absolute w-full h-full bg-opacity-30 bg-black flex items-center justify-center z-10">
          <Icon.Loading size="36" />
        </div>
      )}
      <Card title="Set up configuration for 5 selected routes">
        <div className="px-6 pt-5 pb-1 flex items-center">
          <Icon.Calendar className="fill-neutral-40 mr-2" />
          <div className="mr-1">Last updated: </div>
          {routificSettings?.updatedDate &&
          ![1, 1970].includes(moment(routificSettings?.updatedDate).year()) ? (
            <div>
              {moment(routificSettings?.updatedDate).format(
                TIME_FORMAT.GENERAL_DISPLAY + " " + TIME_FORMAT.ETA_TIME
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="h-[60vh]">
          <CustomScrollbar>
            <div className="px-6">
              <div className="flex border-b border-neutral-20 py-6">
                <div className="w-52 mr-4 text-hd5 font-semibold">
                  Optimization
                </div>
                <div className="flex items-center flex-1">
                  <Common.Radio
                    wrapClassName="mb-0 mr-2"
                    label="Shortest time"
                    id="ShortestTime"
                    name="shortestDistance"
                    checked={!formik.values.shortestDistance}
                    onChange={() =>
                      formik.setFieldValue("shortestDistance", false)
                    }
                  />
                  <Common.Radio
                    name="shortestDistance"
                    wrapClassName="mb-0 mr-2 ml-8"
                    label="Shortest distance"
                    id="ShortestDistance"
                    checked={formik.values.shortestDistance}
                    onChange={() =>
                      formik.setFieldValue("shortestDistance", true)
                    }
                  />
                </div>
              </div>
              <div className="flex border-b border-neutral-20 py-6">
                <div className="w-52 mr-4 text-hd5 font-semibold flex items-center">
                  Visit balance
                </div>
                <div className="flex-1">
                  <Common.Selectbox
                    wrapClassName="w-full"
                    options={visitBalance}
                    name="visitBalanceCoefficient"
                    value={formik.values.visitBalanceCoefficient}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="flex border-b border-neutral-20 py-6">
                <div className="w-52 mr-4 text-hd5 font-semibold flex items-center">
                  Capacity
                  {!formik.values.capacityType && (
                    <span
                      data-tip="Please contact Tomsoft to turn on capacity setting for Route Optimisation"
                      className="ml-1.5"
                    >
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  )}
                </div>
                <div className="flex-1">
                  <Common.Selectbox
                    wrapClassName="w-full"
                    options={capacityTypes}
                    name="capacityType"
                    value={formik.values.capacityType}
                    onChange={formik.handleChange}
                    disabled={!formik.values.capacityType}
                  />
                </div>
              </div>
              <div className="flex py-6">
                <div className="w-52 mr-4 text-hd5 font-semibold">Others</div>
                <div className="flex-1">
                  <div className="font-semibold flex items-center mb-1">
                    Traffic condition
                    <span
                      data-tip="Stimulating traffic conditions. The default value is faster. <br />Each step slows down the speed by 25%, so fast would be <br />25% slower from default, while very slow would be twice as slow"
                      className="ml-1.5"
                    >
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  </div>
                  <Common.Selectbox
                    wrapClassName="mb-3 w-full"
                    options={traffic}
                    name="traffic"
                    value={formik.values.traffic}
                    onChange={formik.handleChange}
                  />
                  <div className="font-semibold flex items-center mb-1">
                    Squash time
                    <span
                      data-tip="Useful when you have many stops at the same location. <br />E.g. Normally each stop has a 10 minute duration, <br />but you can squash them to 1 minute to make the total time <br />for 6 stops 15 minutes."
                      className="ml-1.5"
                    >
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  </div>
                  <Common.Input
                    wrapClassName="mb-3 w-full"
                    placeholder="Enter number of mins"
                    name="squashDurations"
                    value={formik.values.squashDurations}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched.squashDurations &&
                      Boolean(formik.errors.squashDurations)
                    }
                    errorMessage={
                      formik.touched.squashDurations
                        ? formik.errors.squashDurations
                        : ""
                    }
                  />
                  <div className="font-semibold flex items-center mb-1">
                    Max visit lateness
                    <span
                      data-tip="This sets the maximum number of minutes you can be late <br />for any of your stops. We only schedule something late <br />if it is going to be unserved otherwise. Also note that the algorithm will <br />minimize and spread out lateness as much as possible, <br />the algorithm favors 5 orders being late by 2 minutes over 1 order <br />being late by 10 minutes.“"
                      className="ml-1.5"
                    >
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  </div>
                  <Common.Input
                    wrapClassName="mb-3 w-full"
                    placeholder="Enter number of visits"
                    name="maxVisitLateness"
                    value={formik.values.maxVisitLateness}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched.maxVisitLateness &&
                      Boolean(formik.errors.maxVisitLateness)
                    }
                    errorMessage={
                      formik.touched.maxVisitLateness
                        ? formik.errors.maxVisitLateness
                        : ""
                    }
                  />
                  <div className="font-semibold flex items-center mb-1">
                    Max vehicle overtime (minutes):
                    <span
                      data-tip="This sets the maximum number of minutes a driver is allowed <br />to work overtime. Note that if all stops can be served <br />within a normal shift, that will always be preferred."
                      className="ml-1.5"
                    >
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  </div>
                  <Common.Input
                    wrapClassName="mb-3 w-full"
                    placeholder="Enter number of vehicle overtime"
                    name="maxVehicleOvertime"
                    value={formik.values.maxVehicleOvertime}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched.maxVehicleOvertime &&
                      Boolean(formik.errors.maxVehicleOvertime)
                    }
                    errorMessage={
                      formik.touched.maxVehicleOvertime
                        ? formik.errors.maxVehicleOvertime
                        : ""
                    }
                  />
                  <div className="flex items-center flex-1">
                    <Common.Checkbox
                      name="avoidTolls"
                      wrapClassName="mb-0 mr-2"
                      id="avoidTolls"
                      checked={formik.values.avoidTolls}
                      onChange={(e) =>
                        formik.setFieldValue("avoidTolls", e.target.checked)
                      }
                    />
                    <div className="font-semibold mr-3">Avoid tolls</div>
                    <span data-tip="This option is useful if you would like to avoid toll roads in your routes">
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 items-center">
          <div className="flex-1 flex mr-4" />
          <Common.Checkbox
            id="applyDefault"
            name="applyDefault"
            label="Apply as default settings"
            checked={isApplyAsDefaultSettings}
            onChange={(e) => setApplyAsDefaultSettings(e.target.checked)}
          />
          <Common.Button
            className="!ml-4"
            onClick={() => formik.handleSubmit()}
            isLoading={routificSettingsLoading && routificSettingsLoaded}
            disabled={routificSettingsLoading || !formik.dirty}
          >
            Save
          </Common.Button>
        </div>
      </Card>
    </ModalContent>
  );
};
