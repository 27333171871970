import { Common, Icon } from "@/components";
import { dropdownIds } from "../configVariable";
import { dropMenuEditItem } from "../models/staticData";

interface Props {
  onSwap: () => void;
  onMove: () => void;
  onChangeDriver?: () => void;
}

const DropdownMenu = [
  // {
  //   id: dropdownIds.MOVETR,
  //   name: "Move to other route",
  //   tooltipContent: "",
  //   icon: <Icon.MoveTo size="12" className="mr-2 fill-neutral-50" />,
  // },
  {
    id: dropdownIds.SWAP_ROUTE,
    name: "Swap route",
    tooltipContent: "",
    icon: <Icon.Switch size="12" className="mr-2 fill-neutral-50" />,
  },
  {
    id: dropdownIds.CHANGE_DRIVER,
    name: "Change driver",
    tooltipContent: "",
    icon: <Icon.Driver size="12" className="mr-2 fill-neutral-50" />,
  },
];

const DeliveryItemActionsDropdown = ({
  onSwap,
  onMove,
  onChangeDriver,
}: Props) => {
  const handleDropdownItemClick = (item: dropMenuEditItem) => {
    switch (item.id) {
      case dropdownIds.SWAP_ROUTE:
        onSwap();
        break;
      case dropdownIds.MOVETR:
        onMove();
        break;
      case dropdownIds.CHANGE_DRIVER:
        onChangeDriver && onChangeDriver();
        break;
      default:
        break;
    }
  };

  return (
    <Common.DropDowns
      buttonRender={
        <div className="w-6 h-6 inline-flex items-center justify-center border font-medium group bg-white text-blue border-neutral-20 hover:border-blue-dark rounded">
          <Icon.SeeMore size="12" className="fill-blue" />
        </div>
      }
      listRender={
        <div>
          {DropdownMenu.map(
            (item) =>
              (item.id !== dropdownIds.CHANGE_DRIVER ||
                onChangeDriver !== undefined) && (
                <Common.DropdownItem
                  key={item.id}
                  icon={item.icon}
                  onClick={() => handleDropdownItemClick(item)}
                >
                  {item.name}
                </Common.DropdownItem>
              )
          )}
        </div>
      }
    />
  );
};

export default DeliveryItemActionsDropdown;
