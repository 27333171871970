import { RootState } from "@/sharedStore/store";

export const manifestsSelector = (state: RootState) =>
  state.deliverySlice.deliveryManifest.manifests;
export const routesSelector = (state: RootState) =>
  state.deliverySlice.deliveryManifest.routeList;
export const unallocatedDocumentCountSelector = (state: RootState) =>
  state.deliverySlice.deliveryManifest.unallocatedDocumentCount;
export const deliveryManifest = (state: RootState) =>
  state.deliverySlice.deliveryManifest;
export const deliveryManifestLoading = (state: RootState) =>
  state.deliverySlice.loading;
export const deliveryManifestLoaded = (state: RootState) =>
  state.deliverySlice.loaded;

export const storageAddressSelector = (state: RootState) =>
  state.deliverySlice.storageAddress;
export const storageAddressLoadedSelector = (state: RootState) =>
  state.deliverySlice.storageAddressLoaded;
export const storageAddressLoadingSelector = (state: RootState) =>
  state.deliverySlice.storageAddressLoading;

// export const manifestETALoadingSelector = (state: RootState) =>
//   state.deliverySlice.manifestETALoading;
// export const manifestETASelector = (state: RootState) =>
//   state.deliverySlice.manifestETA;

export const routificSettingsSelector = (state: RootState) =>
  state.deliverySlice.routificSettings;
export const routificSettingsLoadingSelector = (state: RootState) =>
  state.deliverySlice.routificSettingsLoading;
export const routificSettingsLoadedSelector = (state: RootState) =>
  state.deliverySlice.routificSettingsLoaded;

export const optimizingGroupsSelector = (state: RootState) =>
  state.deliverySlice.optimizingGroups;
