import { useCallback } from "react";
import { useAppSelector } from "@/sharedStore/hooks";
import { manifestDetailETA } from "../../models";

export const useGetRouteList = () => {
  const routeList = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.routeList
  );
  return useCallback(() => {
    return routeList;
  }, [routeList]);
};

export const useGetDeliveryRouteById = () => {
  const routeList = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.routeList
  );
  return useCallback(
    (id: string) => {
      if (routeList) {
        return routeList.find((value) => value.id === id);
      }
    },
    [routeList]
  );
};

export const useGetRouteDetailById = () => {
  const manifests = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.manifests
  );
  return useCallback(
    (id: string) => {
      if (manifests) {
        return manifests.find((value) => value.id === id);
      }
    },
    [manifests]
  );
};

export const useGetRouteDetailByRouteId = () => {
  const manifests = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.manifests
  );
  return useCallback(
    (id: string) => {
      if (manifests) {
        return manifests.find((value) => value.routeId === id);
      }
    },
    [manifests]
  );
};

export const useGetRouteById = () => {
  const manifests = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.manifests
  );
  return useCallback(
    (id: string) => {
      if (manifests) {
        return manifests.find((value) => value.id === id);
      }
    },
    [manifests]
  );
};

export const useGetETAByManifestId = () => {
  const manifestETA = useAppSelector(
    (state) => state.deliverySlice.manifestETA
  );
  return useCallback(
    (id: string) => {
      if (manifestETA) {
        return manifestETA.find(
          (value: manifestDetailETA) => value?.manifestId === id
        );
      }
    },
    [manifestETA]
  );
};

export const useSolutionByJobId = () => {
  const optimizingGroups = useAppSelector(
    (state) => state.deliverySlice.optimizingGroups
  );
  return useCallback(
    (jobId: string) => {
      if (optimizingGroups && optimizingGroups.length) {
        return optimizingGroups.find((value: any) => value?.jobId === jobId);
      }
    },
    [optimizingGroups]
  );
};

export const useGetAccountInfo = () => {
  const accountInfo = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.accountInfo
  );
  return useCallback(() => {
    return accountInfo;
  }, [accountInfo]);
};

export const useGetCapacityType = () => {
  const accountInfo = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.accountInfo
  );
  return useCallback(() => {
    return Number(accountInfo?.capacityType) ?? undefined;
  }, [accountInfo]);
};

// export const useGetProductPrice = () => {
//   const getProductById = useGetProductById();
//   return useCallback(
//     (productId: number) => getProductById(productId)?.price || 0,
//     [getProductById]
//   );
// };
